<template>
  <v-row>
    <v-col sm="12" md="12">
      <v-card>
        <v-card-title>
          <v-icon class="mr-2">mdi-cash-multiple</v-icon>
          Solicitações de Pagamento
          <v-spacer></v-spacer>
          <v-text-field
            v-model="paginationOptions.search"
            class="mr-3"
            append-icon="mdi-magnify"
            label="Buscar"
            placeholder="Informe o Nome Fantasia ou Razão Social"
            single-line
            hide-details
            @keydown="buscar()"
            @click:append="buscar()"
          ></v-text-field>
          <v-spacer></v-spacer>
        </v-card-title>

        <v-row>
          <v-col md="12">
            <v-alert
              dense
              outlined
              type="warning"
              v-if="!bmFinancePagination && !bmFinances">
              Beauty Spots não encontrados
            </v-alert>
          </v-col>
        </v-row>

        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="filteredBmFinancesItems()"
          :options.sync="options"
          :server-items-length="bmFinances.totalItems"
          :search.sync="paginationOptions.search"
          item-key="id"
          class="elevation-1"
          v-show="bmFinances.items"
          :footer-props="{'items-per-page-options': [10, 25, 50, 75, 100]}"
        >

          <template v-slot:item.valueReleased="{ item }">
            {{ item.valueReleased | money }}
          </template>
          <template v-slot:item.valueAnticipation="{ item }">
            {{ item.valueAnticipation | money }}
          </template>
          <template v-slot:item.valueRequested="{ item }">
            {{ item.valueRequested | money }}
          </template>
          <template v-slot:item.totalValue="{ item }">
            {{ item.totalValue | money }}
          </template>
          <template v-slot:item.requestDate="{ item }">
            {{ item.requestDate | formatDate2 }}
          </template>
          <template v-slot:item.payDate="{ item }">
            {{ item.payDate | formatDate2 }}
          </template>
          <template v-slot:item.paid="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{on, attrs}">
                <v-btn
                  :color="item.paid ? 'green' : 'red'"
                  icon
                  v-on="on"
                  v-bind="attrs"
                >
                  <v-icon>{{item.paid ? 'mdi-checkbox-marked': 'mdi-alpha-x-box'}}</v-icon>
                </v-btn>
              </template>
              <span>{{ item.paid | formatBoolean }}</span>
            </v-tooltip>
          </template>


          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="#3D1159"
                  icon
                  v-bind="attrs"
                  v-on="on"
                  :to="{ name: 'bmFinanceView', params: { id: item.id }}"
                >
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
              </template>
              <span>Visualizar</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import {mapGetters} from "vuex";
import store from "@/store";

import {BM_FINANCE_SEARCH} from "@/store/actions/bm_finance.type";
import {BM_FINANCE_RESET_STATE, SET_BM_FINANCE_PAGINATION} from "@/store/mutations/bm_finance.type";

export default {
  name: "BMFinance",
  props: {
    source: String
  },

  async beforeRouteUpdate(to, from, next) {
    // Reset state if user goes from /editor/:id to /editor
    // The component is not recreated so we use to hook to reset the state.
    return next();
  },
  async beforeRouteEnter(to, from, next) {
    Promise.all([
      store.commit(BM_FINANCE_RESET_STATE),
      store.dispatch(BM_FINANCE_SEARCH)
    ]).then(() => {
      next();
    });
  },
  async beforeDestroy() {
        if(!this.$route.path.includes('/bm_finance')){
            localStorage.removeItem('bmFinanceOptions')
            localStorage.removeItem('bmFinancePaginationOptions')
        }
    },

  data: () => ({
    headers: [
      {text: "Razão Social", value: "companyName", align: "left"},
      {text: "CNPJ", value: "cnpj"},
      {text: "Saldo Liberado", value: "valueReleased", align: "left"},
      {text: "Saldo a Liberar", value: "valueAnticipation", align: "left"},
      {text: "Valor Solicitado", value: "valueRequested", align: "left"},
      {text: "Valor Líquido", value: "totalValue", align: "left"},
      {text: "DT Pedido", value: "requestDate", align: "left"},
      {text: "DT Pag", value: "payDate", align: "left"},
      {text: "Pago", value: "paid", align: "center", sortable: false},

      // {text: "Categoria", value: "categories", align: "left"},
      {text: "Visualizar", value: "actions", align: "center", sortable: false}
    ],
    selected: null,
    options: {},
    paginationOptions: {},

    //rules
    textRules: [
      // v => !!v || "Este campo é obrigatório",
      v =>
        (v && v <= 900) || "O valor deste campo não pode exceder mais que 900"
      //v => v > 0 || "Esta campo deve ter um valor maior que zero."
    ]
  }),

  methods: {

    buscar() {
      const {sortBy, sortDesc, itemsPerPage, page} = this.options
      this.bmFinancePagination.search = this.paginationOptions.search
      store.commit(SET_BM_FINANCE_PAGINATION, {
        page: page,
        itemsPerPage: itemsPerPage,
        orderBy: sortBy,
        sortDesc: sortDesc[0]
      })
      store.dispatch(BM_FINANCE_SEARCH);
    },
    filteredBmFinancesItems() { 
        const items = this.bmFinances.items
        if(this.bmFinancePagination.search){
            const search = this.bmFinancePagination.search.toLowerCase()
            this.options.itemsPerPage = this.bmFinances.totalItems
            this.options.page = 1
            const exactMatches = [];
            const partialMatches = [];

            items.forEach(item => {
                const itemValues = Object.values(item).map(value => String(value).toLowerCase());
                const isExactMatch = itemValues.some(value => value.startsWith(search));
                const isPartialMatch = itemValues.some(value => value.includes(search));
                
                if (isExactMatch) {
                    exactMatches.push(item);
                } else if (isPartialMatch) {
                    partialMatches.push(item);
                }
            });

            return [...exactMatches,...partialMatches];
        }
        else {
            if(this.options.itemsPerPage == this.bmFinances.totalItems){
                this.options.itemsPerPage = 10
            }
            return items;
        } 
    }

  },
  mounted() {
      if (localStorage.getItem('bmFinanceOptions')){
          this.options = JSON.parse(localStorage.getItem('bmFinanceOptions'))
      }
      if (localStorage.getItem('bmFinancePaginationOptions')){
          this.paginationOptions = JSON.parse(localStorage.getItem('bmFinancePaginationOptions'))
      }
  },
  watch: {
    options: {
      handler(newOptions) {
        this.buscar()
        const parsed = JSON.stringify(newOptions)
        localStorage.setItem('bmFinanceOptions', parsed)
      },
      deep: true,
    },
    paginationOptions: {
          handler(newSearch) {
              this.buscar()
              const parsed = JSON.stringify(newSearch)
              localStorage.setItem('bmFinancePaginationOptions', parsed)
          },
          deep: true,
      }
  },
  computed: {
    ...mapGetters(["bmFinances", "checkUsers", "bmFinancePagination"])
  }
};
</script>
